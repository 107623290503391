//
//
// font-family.scss
//
//

/* ------------------------------------------------------------
 font-family
------------------------------------------------------------ */
:root{
	--font-gothic    : 'ryo-gothic-plusn', sans-serif;
	--font-sans-serif: 'gill-sans-nova' , sans-serif;
	--font-mix       : 'gill-sans-nova' , 'ryo-gothic-plusn' , sans-serif;
}

