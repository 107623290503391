/**
 *
 * loopslider.scss
 *
 */
/* ------------------------------------------------------------
 loopslider-x
------------------------------------------------------------ */
@keyframes loopslider-x{
	0%{
		transform: translate3d(0,0,0);
	}
	100%{
		transform: translate3d(-100%,0,0);
	}
}
