/**
 *
 * pointerevent.scss
 *
 */
/* ------------------------------------------------------------
 pointerevent
------------------------------------------------------------ */
.u-pointerevent-none{ pointer-events: none;}


