//
//
// color.scss
//
//

/* ------------------------------------------------------------
 color
------------------------------------------------------------ */
:root{
	/* ------------------------------------------------------------
	 palette
	------------------------------------------------------------ */
	--pallet-transp: rgba(0,0,0,0);



	/* ------------------------------------------------------------
	 color
	------------------------------------------------------------ */
	// --color-main     : #ffffff;
	// --color-main-pale: #656e90;
	// --color-accent   : #ffffff;
	// --color-accent   : #ffffff;

	// --color-link: var( --color-main );
	--color-hover  : #444;
	--hover-opacity: 0.5;
	--hover-scale  : 1.15;
	--hover-scale2 : 0.7;

	--color-bg : #ffffff;
	--color-bg2: #000000;
	--color-bg3: #f2f2f2;
	--color-bg4: #fafafa;
	--color-bg5: #d9d9d9;

	--color-overlay: rgba(0,0,0,.7);

	--color-txt : #000000;
	--color-txt2: #ffffff;
	--color-txt3: #999999;

	--color-line : #000000;

	--color-icon: #cbcbcb;

	// --color-gradation: linear-gradient(0deg, var( --color-main-pale ) , var( --color-main ) );


	/* ----------------------------------------
	 ui
	---------------------------------------- */
	/* ---------- input ---------- */
	--color-input-bg          : #ffffff;
	--color-input-bg-hover    : #f2f2f2;
	--color-input-bg-focus    : #ffffff;
	--color-input-bg-done     : #ffffff;
	--color-input-bg-error    : #ffffff;
	--color-input-border      : #000000;
	--color-input-border-error: #ff0000;
	--color-input-placeholder : #808080;

	/* ---------- state ---------- */
	--color-required: #000000;
	--color-error   : #ff0000;
	--color-error-bg: rgba(255, 0, 0, 0.1);


}






/* ------------------------------------------------------------
 theme
------------------------------------------------------------ */




