/**
 *
 * icon.scss
 *
 */

/* ------------------------------------------------------------
 c-icon
------------------------------------------------------------ */
.c-icon{
	display: inline-block;
	vertical-align: middle;
	letter-spacing: 0;
	line-height: 0;
}

