//
//
// math.scss
//
//

/* ------------------------------------------------------------
 math
 数学的変数の設定
------------------------------------------------------------ */
:root{
	// --root2: 1.41421;
	// --root3: 1.73205;
	--heptagon: polygon(50% 0%,9.9% 19.8%,0% 64.3%,27.7% 100%,72.3% 100%,100% 64.3%,90.1% 19.8% ); // ７角形
}


