/**
 *
 * svg.scss
 *
 */
/* ------------------------------------------------------------
 js-svg
------------------------------------------------------------ */
svg.js-svg{
	// width: 100%;
}

img{
	&.js-svg{
		opacity: 0;
	}
}

