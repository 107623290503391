/**
 *
 * flashing.scss
 *
 */
/* ------------------------------------------------------------
 flashing
------------------------------------------------------------ */
@keyframes flashing{
	0% { opacity: 1; }
	15%{ opacity: 0; }
	30%{ opacity: 1; }
	45%{ opacity: 0; }
	60%{ opacity: 1; }
}
