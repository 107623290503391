/**
 *
 * sticky.scss
 *
 */
/* ------------------------------------------------------------
 c-sticky
------------------------------------------------------------ */
.c-sticky{
	@include mq-up{
		position: sticky;
		@include rem( top , 100px );
	}
}

