//
//
// layout.scss
//
//

///////////////////////////////////////////////////////////////
// block
///////////////////////////////////////////////////////////////
@mixin display-block{
	display: block;
	width: 100%;
	height: 100%;
}

///////////////////////////////////////////////////////////////
// margin
///////////////////////////////////////////////////////////////
@mixin margin-auto( $width:90% ) {
	width: $width;
	margin: 0 auto;
}













