//
//
// placeholder.scss
//
//

///////////////////////////////////////////////////////////////
// placeholder
///////////////////////////////////////////////////////////////
//
// overview : フォームのplaceholderのスタイル
//
@mixin placeholder {
	&:placeholder-shown {
		@content;
	}
	&:placeholder {
		@content;
	}
	&::-webkit-input-placeholder {
		@content;
	}
	&:-moz-placeholder {
		opacity: 1;
		@content;
	}
	&::-moz-placeholder {
		opacity: 1;
		@content;
	}
	&:-ms-input-placeholder {
		@content;
	}
}



