/**
 *
 * change.scss
 *
 */
/* ------------------------------------------------------------
 change
------------------------------------------------------------ */
@keyframes change{
	0%  { opacity: 0; }
	20% { opacity: 1; }
	50% { opacity: 1; }
	70%{ opacity: 0; }
	100%{ opacity: 0; }
}
