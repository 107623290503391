/**
 *
 * bg.scss
 *
 */
/* ------------------------------------------------------------
 background-color
------------------------------------------------------------ */
// .u-bg-none  { background-color: rgba(0,0,0,0); }

.u-bg3{ background-color: var( --color-bg3 ); }

// @include utility-style( background-color , bg , $pallets-neutral );
// @include utility-style( background-color , bg , $pallets-main );




