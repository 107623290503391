/**
 *
 * main.scss
 *
 */
/* ------------------------------------------------------------
 l-main
------------------------------------------------------------ */
.l-main{
	position: relative;
	padding-top: 0.1px;
}