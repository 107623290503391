/**
 *
 * lazy.scss
 *
 */
/* ------------------------------------------------------------
 c-lazy
------------------------------------------------------------ */
.c-lazy{
	content-visibility: auto;
}

