/**
 *
 * lazyload.scss
 *
 */
/* ------------------------------------------------------------
 js-lazyload
------------------------------------------------------------ */
.js-lazyload{
	opacity: 0;

	&.is-set{
		opacity: 1;
		transition: opacity 200ms linear;
	}
}
