/**
 *
 * loading.scss
 *
 */
/* ------------------------------------------------------------
 l-loading
------------------------------------------------------------ */
.l-loading{
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	height: 100%;
	@include position-fixed;
	background-color: var( --color-bg );
	@include z-index(l-loading);

	body.is-load &{
		opacity: 0;
		visibility: hidden;
		transition: var( --transition-loading );
		transition-property: opacity,visibility;
		pointer-events: none;
	}
}

.l-loading__counter{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;

	&.is-visible{
		opacity: 1;
		visibility: visible;
	}
}

.l-loading__symbol{
	@include mq-up{
		@include rem( width , 92px );
	}
	@include mq-down{
		@include rem( width , 40px );
	}
}

.l-loading__counter__txt{
	@include font-family(sans-serif);
	@include font-weight(semi-bold);
	@include letter-spacing(100);

	@include mq-up{
		@include font-var(13);
		@include rem( margin-top , 32px );
	}
	@include mq-down{
		@include font-var(10);
		@include rem( margin-top , 20px );
	}
}






