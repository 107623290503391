/**
 *
 * text.scss
 *
 */
/* ------------------------------------------------------------
 text
------------------------------------------------------------ */
// .u-t-indent         { text-indent: 1em; }
// .u-t-kome           { padding-left: 1em; text-indent: -1em; }
// .u-t-lineheight     { line-height: 1; }
// .u-t-nowrap         { white-space: nowrap; }
// .u-t-underline      { text-decoration: underline !important; }
// .u-t-underline-none { text-decoration: none !important; }

// .u-t-right   { text-align: right; }
// .u-t-left    { text-align: left; }
.u-t-center  { text-align: center; }
// .u-t-justify { text-align: justify; }

// @include mq-up(md){
// 	.u-t-right-mqUp-md   { text-align: right; }
// 	.u-t-left-mqUp-md    { text-align: left; }
// 	.u-t-center-mqUp-md  { text-align: center; }
// 	.u-t-justify-mqUp-md { text-align: justify; }
// }
// @include mq-up(xs){
// 	.u-t-right-mqUp-xs   { text-align: right; }
// 	.u-t-left-mqUp-xs    { text-align: left; }
// 	.u-t-center-mqUp-xs  { text-align: center; }
// 	.u-t-justify-mqUp-xs { text-align: justify; }
// }
// @include mq-up(sm){
// 	.u-t-right-mqUp-sm   { text-align: right; }
// 	.u-t-left-mqUp-sm    { text-align: left; }
// 	.u-t-center-mqUp-sm  { text-align: center; }
// 	.u-t-justify-mqUp-sm { text-align: justify; }
// }
// @include mq-down(md){
// 	.u-t-right-mqDown-md   { text-align: right; }
// 	.u-t-left-mqDown-md    { text-align: left; }
// 	.u-t-center-mqDown-md  { text-align: center; }
// 	.u-t-justify-mqDown-md { text-align: justify; }
// }
// @include mq-down(xs){
// 	.u-t-right-mqDown-xs   { text-align: right; }
// 	.u-t-left-mqDown-xs    { text-align: left; }
// 	.u-t-center-mqDown-xs  { text-align: center; }
// 	.u-t-justify-mqDown-xs { text-align: justify; }
// }
// @include mq-down(sm){
// 	.u-t-right-mqDown-sm   { text-align: right; }
// 	.u-t-left-mqDown-sm    { text-align: left; }
// 	.u-t-center-mqDown-sm  { text-align: center; }
// 	.u-t-justify-mqDown-sm { text-align: justify; }
// }

