//
//
// clearfix.scss
//
//

///////////////////////////////////////////////////////////////
// clearfix
///////////////////////////////////////////////////////////////
//
// overview: after要素にclearfixを適用
//
@mixin clearfix{
	&::after{
		content:".";
		display:block;
		clear:both;
		height:0;
		line-height:0;
		visibility:hidden;
	}
}















