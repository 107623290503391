//
//
// line.scss
//
//

/* ------------------------------------------------------------
 line
------------------------------------------------------------ */
:root{
	/* ------------------------------------------------------------
	 solid
	------------------------------------------------------------ */
	--solid-current: solid 1px currentColor;
	--solid        : solid 1px var( --color-line );
	// --solid3: solid 1px rgba( #{0,0,0} ,.2);

	// --solid-gray : solid 1px #cccccc;
	// --solid-white: solid 1px rgba( #{255,255,255} ,.4);

	// --solid-bold : solid 4px rgba( #{0,0,0} ,.1);


}