//
//
// font-size.scss
//
//

/* ------------------------------------------------------------
 font-size
 max関数
 13〜39pxまではmax関数を使用し可変する。
 非対応ブラウザはJavaScriptのalternativeFontSizeMaxで生成
------------------------------------------------------------ */
:root{
	///////////////////////////////////////////////////////////////
	// $font-sizesでは10〜200までのfont-sizeが格納されています。
	// それ以上は個別で指定してください。
	///////////////////////////////////////////////////////////////
	@include var-font-size((
		1,2,3,4,5,6,7,8,9,
		10,11,12,13,14,15,16,17,18,19,
		20,21,22,23,24,25,26,27,28,29,
		30,31,32,33,34,35,36,37,38,39,
		40,41,42,43,44,45,46,47,48,49,
		50,51,52,53,54,55,56,57,58,59,
		60,61,62,63,64,65,66,67,68,69,
		70,71,72,73,74,75,76,77,78,79,
		80,81,82,83,84,85,86,87,88,89,
		90,91,92,93,94,95,96,97,98,99,
		100,
		140,
	));

	// @include mq-down(sm){
	// 	@include var-font-size((
	// 	10,11,12,13,14,15,16,17,18,19,
	// 	20,21,22,23,24,25,26,27,28,29,
	// 	30,32,33,34,35,36,37,38,
	// 	),$font-sizes-mqDown-sm);
	// }

	/* ----- 英字の上下の余白削除 ----- */
	--font-sans-serif-top   : -0.226em;
	--font-sans-serif-bottom: -0.08em;

}

