/**
 *
 * rotate.scss
 *
 */
/* ------------------------------------------------------------
 rotate
------------------------------------------------------------ */
@keyframes rotate{
	0% {
		transform: rotate(-2deg)
	}
	50% {
		transform: rotate(2deg)
	}
	100% {
		transform: rotate(-2deg)
	}
}
