/**
 *
 * sitemap.scss
 *
 */
/* ------------------------------------------------------------
 l-sitemap
------------------------------------------------------------ */
.l-sitemap{
	position: fixed;
	top: 0;
	right: 0;
	@include z-index(l-sitemap);
	width: 100%;
	height: 100%;
	// display: grid;
	background-color: var( --color-bg2 );
	color: var( --color-txt2 );
	overflow-y: auto;
	-webkit-overflow-scrolling: touch;
	overscroll-behavior: none;
	opacity: 0;
	visibility: hidden;
	transition: var( --transition-sitemap );
	transition-property: opacity,visibility;

	&.is-open{
		opacity: 1;
		visibility: visible;
	}
}

.l-sitemap__inner{
	display: flex;
	flex-direction: column;
	justify-content: center;

	@include mq-up{
		@include rem( padding , 85px 0 282px);
	}
	@include mq-down{
		@include rem( padding , 27px 0 80px);
	}
}



/* ------------------------------------------------------------
 l-sitemap__header
------------------------------------------------------------ */
.l-sitemap__logo{
	svg{
		@include mq-up{
			@include rem( width , 324px * .8 );
			@include rem( height , 52px * .8 );
		}
		@include mq-up(xxl){
			@include rem( width , 324px );
			@include rem( height , 52px );
		}
		@include mq-down{
			@include rem( width , 216px );
			@include rem( height , 35px );
		}
	}
}

.l-sitemap__service{
	// text-transform: uppercase;
	@include font-family(sans-serif);
	@include var-line-height(13,23);
	@include letter-spacing(100);

	@include mq-up{
		@include font-var(13);
		@include rem( margin-top , 34px );
	}
	@include mq-down{
		width: var( --contents );
		margin: 0 auto;
		@include font-var(7);
		@include rem( margin-top , 48px );
	}
}







/* ------------------------------------------------------------
 l-sitemap__main
------------------------------------------------------------ */
.l-sitemap__main{
	@include mq-up{
		display: flex;
		justify-content: space-between;
		@include rem( margin-top , 100px );
	}
	@include mq-down{
		display: grid;
		@include rem( gap , 54px );
		width: var( --contents );
		margin: 0 auto;
		@include rem( margin-top , 54px );
	}

	> ul{
		display: grid;

		@include mq-up{
			@include rem( gap , 170px );
		}
		@include mq-down{
			@include rem( gap , 54px );
		}
	}

	a{
		transition: none;

		@include hover{
			.l-sitemap__main__en{
				opacity: var( --hover-opacity );
			}
		}
	}
}


.l-sitemap__main__en{
	display: block;
	text-transform: uppercase;
	@include letter-spacing(50);
	transition: opacity var( --transition-hover );

	span{
		text-transform: uppercase;
		@include font-family(sans-serif);
		@include font-weight(medium);

		@include mq-up{
			@include font-var(22);
		}
		@include mq-up(xxl){
			@include font-var(27);
		}
		@include mq-down{
			@include font-var(26);
		}
	}
}

.l-sitemap__main__ja{
	display: block;
	@include font-weight(medium);

	@include mq-up{
		@include font-var(14);
		@include rem( margin-top , 25px );
	}
	@include mq-down{
		@include font-var(13);
		@include rem( margin-top , 16px );
	}
}


/* ---------- l-sitemap__parent ---------- */
.l-sitemap__parent{
	display: block;
	width: fit-content;

	@include mq-down{
		@include rem( padding-left , 25px );
	}

	.l-sitemap__main__en{
		position: relative;

		&::before{
			content: '';
			display: block;
			height: 1px;
			background-color: currentColor;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			@include mq-up{
				@include rem( width , 14px );
				@include rem( left , -26px );
			}
			@include mq-up(xxl){
				@include rem( width , 18px );
				@include rem( left , -30px );
			}
			@include mq-down{
				@include rem( width , 15px );
				@include rem( left , -25px );
			}
		}
	}
}


/* ---------- l-sitemap__children ---------- */
.l-sitemap__children{
	display: grid;

	@include mq-up{
		@include rem( gap , 63px );
		@include rem( margin-top , 60px );
	}
	@include mq-down{
		@include rem( gap , 33px );
		@include rem( margin-top , 54px );
		@include rem( margin-left , 25px );
	}

	.l-sitemap__main__en{
		span{
			@include mq-up{
				@include font-var(22);
			}
		}
	}

	.l-sitemap__main__ja{
		@include mq-up{
			@include rem( margin-top , 20px );
		}
	}
}


/* ---------- l-sitemap__children2 ---------- */
.l-sitemap__children2{
	@include font-weight(medium);
	@include letter-spacing(100);

	@include mq-up{
		display: flex;
		align-items: flex-start;
		justify-content: space-between;
		@include rem( gap , 30px );
		@include rem( margin-top , 60px );
		@include font-var(11);
	}
	@include mq-up(xxl){
		@include rem( gap , 50px );
		@include font-var(14);
	}
	@include mq-down{
		display: grid;
		@include rem( gap , 30px );
		@include rem( margin-top , 43px );
		@include rem( margin-left , 25px );
		@include font-var(16);
	}

	a,button{
		color: var( --color-txt3 );
	}
}

.l-sitemap__children2__title{
	button{
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
	}

	.c-plus{
		@include mq-up{
			display: none;
		}
	}
}

.l-sitemap__children2__list{
	ul{
		display: grid;

		@include mq-up{
			@include rem( gap , 10px );
			@include rem( padding-top , 33px );
		}
		@include mq-down{
			@include rem( gap , 32px );
			@include rem( padding-top , 24px );
			@include rem( padding-bottom , 20px );
		}
	}

	a{
		display: inline-block;
		@include anchor-padding;
		transition: color var( --transition-hover );

		@include mq-down{
			@include font-weight(regular);
			letter-spacing: 0;
		}

		@include hover{
			color: var( --color-txt2 );
		}
	}
}





