//
//
// calc.scss
//
//

///////////////////////////////////////////////////////////////
// calcPercent
///////////////////////////////////////////////////////////////
//
// overview: calcでパーセントを指定する関数。パーセントにするために「* 100%」が末尾に入ります。
// usage   : width: calc( 50% - 4.0rem );
//
@function calcPercent( $value ) {
	$calc: calc( ( #{$value} ) * 100% );
	@return $calc;
}



///////////////////////////////////////////////////////////////
// calcVw
///////////////////////////////////////////////////////////////
//
// overview: calcでvwを指定する関数。vwにするために「* 100%」が末尾に入ります。
//            第一引数には値、第二引数にはデザイン（全体）の横幅が返ります。
//
@function calcVw($value,$designWidth:750){
	$vw: calc( #{$value} / #{$designWidth} * var( --ww ) );

	@return $vw;
}



///////////////////////////////////////////////////////////////
// calcVh
///////////////////////////////////////////////////////////////
//
// overview: calcでvhを指定する関数。vhにするために「* 100%」が末尾に入ります。
//            第一引数には値、第二引数にはデザイン（全体）の横幅が返ります。
//
@function calcVh($value,$designHeight:750){
	$vh: calc( #{$value} / #{$designHeight} * 100vh );

	@return $vh;
}



///////////////////////////////////////////////////////////////
// calcEm
///////////////////////////////////////////////////////////////
//
// overview: calcでvwを指定する関数。vwにするために「* 100%」が末尾に入ります。
//            第一引数には値、第二引数にはデザイン（全体）の横幅が返ります。
//
@function calcEm($value,$base){
	$em: calc( #{$value} / #{$base} * 1em );

	@return $em;
}

