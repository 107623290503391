/**
 *
 * bg.scss
 *
 */
/* ------------------------------------------------------------
 c-bg
 背景にしたい場合に使用します。
------------------------------------------------------------ */
// .c-bg{
// 	width: 100%;
// 	height: 100%;
// 	position: absolute !important;
// 	top: 0;
// 	left: 0;
// 	z-index: -1;
// 	overflow: hidden;

// 	img{
// 		transform: scale(1.01) rotate(0.00001deg);
// 		transition: transform var( --transition-hover ) , opacity .2s linear !important;
// 		// will-change: transform;

// 		@include hover-query{
// 			a:hover &{
// 				transform: scale(1.05) rotate(0.00001deg);
// 			}
// 		}
// 	}
// }



/* ------------------------------------------------------------
 c-bg2
 bg2はhoverで拡大のみ
------------------------------------------------------------ */
.c-bg2{
	overflow: hidden;

	img{
		transition: transform var( --transition-hover ) , opacity .2s linear !important;

		@include hover-query{
			a:hover &{
				transform: scale(var( --hover-scale ));
			}
		}
	}
}


/* ------------------------------------------------------------
 c-bg-fixed
 背景をfixedさせたい時に使用します。

 固定させたい要素を「c-bg-fixed__bg」
 全体を囲む親要素を「c-bg-fixed」として使用します。
------------------------------------------------------------ */
// .c-bg-fixed{
// 	clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
// 	z-index: -1;
// }

// .c-bg-fixed__bg{
// 	display: block;
// 	width: 100%;
// 	height: 100vh;
// 	position: fixed !important;
// 	top: 0;
// 	left: 0;
// 	z-index: -1;
// 	pointer-events: none;
// }




/* ------------------------------------------------------------
 c-bg-texture
 背景にテクスチャをかけたい場合に使用します。
------------------------------------------------------------ */
// .c-bg-texture{
// 	position: relative;

// 	&::after{
// 		content: '';
// 		@include display-block;
// 		background-image: url('../img/common/texture.png');
// 		background-size: 1440px auto;
// 		background-repeat: repeat;
// 		opacity: .014;
// 		@include position-absolute;
// 		z-index: -1;
// 		pointer-events: none;
// 	}
// }





