//
//
// z-index.scss
//
//

///////////////////////////////////////////////////////////////
// z-index
///////////////////////////////////////////////////////////////
//
// overview : z-index CSS変数バージョン
// usage    : 第一引数に自分のクラスメイを入れる。
// caution  : CSS変数側でも指定する必要があり、あくまでこのmixinは入力を楽にするためだけのもの。
//
@mixin z-index( $name ){
	z-index: var( --z-index-#{$name} );
}



